<template>
  <header class="bg-gray-dark py-40">
    <nav
      class="max-w-screen-2xl mx-auto px-24 xs:px-36 sm:px-44 lg:px-52 md:px-60 grid grid-cols-12 gap-x-12 xs:gap-x-16 sm:gap-x-24 lg:gap-x-32 xl:gap-x-40 flex h-auto px-16 justify-between items-center gap-y-32"
    >
      <div class="col-span-12 xl:col-span-3">
        <p
          class="font-main text-30 leading-30 text-gray-light text-center xl:text-left"
        >
          FVF ESPORT
        </p>
      </div>
      <div class="col-span-12 xl:col-span-3 text-center">
        <a
          href="https://www.fifa.gg/c/fifae-nations-series-2022"
          target="_blank"
          rel="noopener noreferrer"
          class="font-action text-14 leading-14 text-gray-default underline transition-opacity ease-in duration-200 hover:opacity-80"
        >
          FIFA NATIONS SERIES 2002
        </a>
      </div>
      <div class="col-span-12 xl:col-span-3 text-center">
        <a
          href="http://www.federacionvenezolanadefutbol.org/"
          target="_blank"
          rel="noopener noreferrer"
          class="font-action text-14 leading-14 text-gray-default underline transition-opacity ease-in duration-200 hover:opacity-80"
        >
          FEDERACIÓN VENEZOLANA DE FÚTBOL
        </a>
      </div>
      <div
        class="col-span-12 xl:col-span-3 text-center xl:text-right mt-16 lg:mt-0"
      >
        <a
          href="https://www.instagram.com/fvfesports/"
          target="_blank"
          rel="noopener noreferrer"
          class="group relative font-action text-14 py-12 px-24 leading-30 border-2 border-wine-light transition-colors ease-in duration-200"
        >
          <span
            class="absolute block inset-0 w-full scale-x-0 h-full bg-wine-light origin-left group-hover:scale-x-100 transition-transform ease-in duration-500 delay-200"
          ></span>
          <span
            class="relative text-wine-light group-hover:text-gray-light duration-500 delay-200"
          >
            visítanos en instagram
          </span>
        </a>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "NavBar",
  components: {},
  data() {
    const navigation_items = [
      { title: "about", url: "./#about" },
      { title: "work", url: "./#work" },
      { title: "skills", url: "./#skills" },
    ];
    return {
      navigation_items,
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
