<template>
  <section>
    <div
      class="max-w-screen-2xl mx-auto px-24 xs:px-36 sm:px-44 lg:px-52 md:px-60 grid grid-cols-12 gap-x-12 xs:gap-x-16 sm:gap-x-24 lg:gap-x-32 xl:gap-x-40 gap-y-64"
    >
      <div
        class="col-start-1 col-span-12 xl:col-start-2 xl:col-span-3 text-gray-light flex flex-col md:flex-row md:flex-wrap md:justify-center gap-40 order-3 xl:order-1"
      >
        <div
          class="flex flex-col xl:flex-row items-center justify-start w-full gap-16"
        >
          <!-- player 1 -->
          <div
            class="relative min-h-[150px] min-w-[150px] rounded-full overflow-hidden"
          >
            <a
              href="https://www.instagram.com/jose.kazanji/"
              target="_blank"
              rel="noopener noreferrer"
              class="group overflow-hidden"
            >
              <img
                class="absolute w-full h-full object-cover group-hover:scale-110 transition-transform ease-in duration-200"
                src="https://res.cloudinary.com/dbyzfcnpg/image/upload/v1653092219/jose-kazanji_cwvzgs.jpg"
                alt="jose kazanji"
              />
            </a>
          </div>
          <div>
            <p class="font-display text-gray-light uppercase">
              Jose kazanji 🎮
            </p>
            <p class="font-display text-gray-default">24 años</p>
            <p class="font-display text-gray-default">jose.kazanji</p>
          </div>
        </div>
        <!-- player 2 -->
        <div
          class="flex flex-col xl:flex-row items-center justify-start w-full gap-16"
        >
          <div
            class="relative min-h-[150px] min-w-[150px] rounded-full overflow-hidden"
          >
            <a
              href="https://www.instagram.com/fabian_pg9/"
              target="_blank"
              rel="noopener noreferrer"
              class="group overflow-hidden"
            >
              <img
                class="absolute w-full h-full object-cover group-hover:scale-110 transition-transform ease-in duration-200"
                src="https://res.cloudinary.com/dbyzfcnpg/image/upload/v1653092499/fabian-penuela_chquoo.jpg"
                alt="fabian peñuela"
              />
            </a>
          </div>
          <div>
            <p class="font-display text-gray-light uppercase">
              fabian peñuela 🎮
            </p>
            <p class="font-display text-gray-default">18 años</p>
            <p class="font-display text-gray-default">fabian_pg9</p>
          </div>
        </div>
        <!-- player 3 -->
        <div
          class="flex flex-col xl:flex-row items-center justify-start w-full gap-16"
        >
          <div
            class="relative min-h-[150px] min-w-[150px] rounded-full overflow-hidden"
          >
            <a
              href="https://www.instagram.com/jesc_7_96/"
              target="_blank"
              rel="noopener noreferrer"
              class="group overflow-hidden"
            >
              <img
                class="absolute w-full h-full object-cover group-hover:scale-110 transition-transform ease-in duration-200"
                src="https://res.cloudinary.com/dbyzfcnpg/image/upload/v1653092715/jose-silva_oov4ma.jpg"
                alt="jose silva"
              />
            </a>
          </div>
          <div>
            <p class="font-display text-gray-light uppercase">
              jose silva 🎮
            </p>
            <p class="font-display text-gray-default">26 años</p>
            <p class="font-display text-gray-default">jesc_7_96</p>
          </div>
        </div>
        <!-- player 4 -->
        <div
          class="flex flex-col xl:flex-row items-center justify-start w-full gap-16"
        >
          <div
            class="relative min-h-[150px] min-w-[150px] rounded-full overflow-hidden"
          >
            <a
              href="https://www.instagram.com/yeickens/"
              target="_blank"
              rel="noopener noreferrer"
              class="group overflow-hidden"
            >
              <img
                class="absolute w-full h-full object-cover group-hover:scale-110 transition-transform ease-in duration-200"
                src="https://res.cloudinary.com/dbyzfcnpg/image/upload/v1653092874/yeickens_cjvzdp.jpg"
                alt="Yeickens Orozco"
              />
            </a>
          </div>
          <div>
            <p class="font-display text-gray-light uppercase">
              Yeickens Orozco 🎮
            </p>
            <p class="font-display text-gray-default">27 años</p>
            <p class="font-display text-gray-default">yeickens</p>
          </div>
        </div>
      </div>
      <div
        class="relative col-start-1 col-span-12 xl:col-start-5 xl:col-span-4 text-gray-light flex flex-col text-center items-center self-center order-1 xl:order-2"
      >
        <img
          class="realtive  h-auto w-[424px] opacity-50 mix-blend-overlay top-0 bottom-0 my-auto"
          :src="`${publicPath}img/fvf-equipo.svg`"
          alt=""
        />
        <div
          class="absolute top-0 bottom-0 m-auto flex flex-col justify-center"
        >
          <span
            class="font-main opacity-20 text-40 lg:text-60 xl:text-80 font-600 leading-40 lg:leading-60 text-gray-light uppercase"
          >
            equipo
          </span>
          <span
            class="font-main opacity-50 text-40 lg:text-60 xl:text-80 font-600 leading-40 lg:leading-60 text-gray-light uppercase"
          >
            equipo
          </span>
          <h2
            class="roster font-heading text-40 lg:text-60 xl:text-80 font-600 leading-40 lg:leading-60 text-gray-light uppercase"
          >
            equipo
          </h2>
          <span
            class="font-main opacity-50 text-40 lg:text-60 xl:text-80 font-600 leading-40 lg:leading-60 text-gray-light uppercase"
          >
            equipo
          </span>
          <span
            class=" font-main opacity-20 text-40 lg:text-60 xl:text-80 font-600 leading-40 lg:leading-60 text-gray-light uppercase"
          >
            equipo
          </span>
        </div>
      </div>
      <div
        class="col-start-1 col-span-12 xl:col-start-9 xl:col-span-3 text-gray-light flex flex-col items-center self-center uppercase order-2 xl:order-3"
      >
        <!-- player dt -->
        <div
          class="flex flex-col xl:flex-row items-center md:justify-start w-full gap-16"
        >
          <div
            class="relative min-h-[150px] min-w-[150px] rounded-full overflow-hidden"
          >
            <a
              href="https://www.instagram.com/rafaleco425/"
              target="_blank"
              rel="noopener noreferrer"
              class="group overflow-hidden"
            >
              <img
                class="absolute w-full h-full object-cover group-hover:scale-110 transition-transform ease-in duration-200"
                src="https://res.cloudinary.com/dbyzfcnpg/image/upload/v1653093151/rafael-guevara_c7ojxh.jpg"
                alt=""
              />
            </a>
          </div>
          <div>
            <p class="font-display text-gray-light uppercase">
              rafael guevara DT 🎮
            </p>
            <p class="font-display text-gray-default">n/a</p>
            <p class="font-display text-gray-default">rafaleco425</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Roster",
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    gsap.to(document.querySelector(".roster"), {
      scrollTrigger: {
        trigger: ".roster",
        start: "top center",
        end: "bottom center",
        scrub: 2,
        // markers: true,
      },
      scale: "1.3",
      duration: 4,
    });
  },
};
</script>
