<template>
  <section class="relative bg-gray-dark pt-52 pb-60">
    <div
      class="max-w-screen-2xl mx-auto px-24 xs:px-36 sm:px-44 lg:px-52 md:px-60 grid grid-cols-12 gap-x-12 xs:gap-x-16 sm:gap-x-24 lg:gap-x-32 xl:gap-x-40"
    >
      <div
        class="row-start-2 md:row-start-1 col-start-1 md:col-start-2 col-span-12 md:col-span-5 z-10 flex flex-col justify-start items-start mt-40 md:mt-44 2xl:mt-[104px] -ml-12 md:-ml-32 xl:-ml-64"
      >
        <div class="relative flex gap-10 items-center py-4">
          <div
            class="absolute w-full h-full bg-yellow opacity-30 rounded-full"
          ></div>
          <span
            class="font-action text-12 font-600 text-gray-dark bg-yellow rounded-full uppercase text-14 py-6 px-8 ml-4"
          >
            destacado
          </span>
          <p class="font-display text-12 text-gray-light pr-10">
            Equipo confirmado
          </p>
        </div>
      </div>
      <div
        class="row-start-1 col-start-1 md:col-start-2 col-span-12 md:col-span-5 z-10 flex flex-col justify-center lg:-mt-128"
      >
        <h1
          class="title font-heading text-40 lg:text-60 xl:text-80 font-600 leading-40 lg:leading-60 xl:leading-80 text-gray-light uppercase"
        >
          ha llegado la nueva generación
        </h1>
        <p
          class="font-display text-16 md:text-14 text-gray-light mt-40 lg:w-1/2"
        >
          Apoya a nuestros chamos en este nuevo torneo digital 🎮
        </p>
      </div>
      <div
        class="relative row-start-1 col-start-2 md:col-start-4 col-span-10 md:col-span-6"
      >
        <div
          class="relative bg-gray-dark h-[600px] md:h-[900px] w-full md:w-[620px] mx-auto rounded-tr-[150px] md:rounded-tr-[200px] lg:rounded-tr-[250px] xl:rounded-tr-[300px] overflow-hidden opacity-50"
        >
          <video
            class="video-plyr absolute inset-0 w-full h-full object-cover"
            autoplay
            muted
            loop
            playsinline
            src="https://res.cloudinary.com/dbyzfcnpg/video/upload/v1653090011/fvf-esport-intro_bzzv7q.mp4"
            poster="https://res.cloudinary.com/dbyzfcnpg/image/upload/v1653092926/hero-placeholder-2_ihmryi.jpg"
          ></video>
        </div>
        <a
          href="https://www.youtube.com/watch?v=MaHNemqrbLc"
          target="_blank"
          rel="noopener noreferrer"
          class="group absolute flex items-center justify-center gap-60 left-[-1rem] bottom-0 font-action text-14 p-14 md:p-44 leading-30 transition-colors ease-in duration-200 z-10"
        >
          <span
            class="absolute block inset-0 w-full h-full bg-gray-dark"
          ></span>
          <span
            class="absolute block inset-0 w-full scale-x-0 h-full bg-wine-light origin-left group-hover:scale-x-100 transition-transform ease-in duration-500 delay-200"
          ></span>
          <span class="relative text-gray-light duration-500 delay-200">
            mira los juegos aqui
          </span>
          <img
            class="self-end transition-transform ease-in duration-200 group-hover:scale-50 scale-50 m-auto"
            :src="`${publicPath}icons/arrow-link.svg`"
            alt=""
          />
        </a>
      </div>
      <div
        class="row-start-3 md:row-start-2 xl:row-start-1 xl:col-start-10 col-span-12 xl:col-span-3 mt-40 md:mt-88"
      >
        <p
          class="flex items-center gap-12 font-display text-gray-default uppercase text-12"
        >
          <span class="relative flex h-8 w-8">
            <span
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green opacity-75"
            ></span>
            <span
              class="relative inline-flex m-auto rounded-full h-6 w-6 bg-green"
            ></span>
          </span>
          últimas noticias
        </p>
        <div class="flex flex-col mt-16 md:mt-60 gap-y-56 gap-y-40">
          <div>
            <a
              class="group"
              href="https://www.instagram.com/p/CddtG66u1Uq/?utm_source=ig_web_copy_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="flex justify-between gap-32">
                <h2
                  class="font-heading text-44 text-gray-light leading-44 font-600 uppercase transition-colors duration-200 group-hover:text-wine-light"
                >
                  Charla de identidad
                </h2>
                <img
                  class="self-end transition-transform ease-in duration-200 group-hover:scale-50"
                  :src="`${publicPath}icons/arrow-link.svg`"
                  alt=""
                />
              </div>
              <p class="font-action text-12 text-gray-light mt-20 uppercase">
                may0 12, 2022
              </p>
              <span
                class="block mt-40 ml-32 origin-left scale-x-50 group-hover:scale-x-100 transition-transform ease-in duration-200 delay-200 border-gray-light border-t-[1px]"
              ></span>
            </a>
          </div>
          <div>
            <a
              class="group"
              href="https://www.instagram.com/p/CdYQbDnupi1/?utm_source=ig_web_copy_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="flex justify-between gap-32">
                <h2
                  class="font-heading text-44 text-gray-light leading-44 font-600 uppercase transition-colors duration-200 group-hover:text-wine-light"
                >
                  nuestro dt
                </h2>
                <img
                  class="self-end transition-transform ease-in duration-200 group-hover:scale-50"
                  :src="`${publicPath}icons/arrow-link.svg`"
                  alt=""
                />
              </div>
              <p class="font-action text-12 text-gray-light mt-20 uppercase">
                mayo 10, 2022
              </p>
              <span
                class="block mt-40 ml-32 origin-left scale-x-50 group-hover:scale-x-100 transition-transform ease-in duration-200 delay-200 border-gray-light border-t-[1px]"
              ></span>
            </a>
          </div>
          <div>
            <a
              class="group"
              href="https://www.instagram.com/p/Ccv7K9KOevp/?utm_source=ig_web_copy_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="flex justify-between gap-32">
                <h2
                  class="font-heading text-44 text-gray-light leading-44 font-600 uppercase transition-colors duration-200 group-hover:text-wine-light"
                >
                  tenemos campeón
                </h2>
                <img
                  class="self-end transition-transform ease-in duration-200 group-hover:scale-50"
                  :src="`${publicPath}icons/arrow-link.svg`"
                  alt=""
                />
              </div>
              <p class="font-action text-12 text-gray-light mt-20 uppercase">
                abril 24, 2022
              </p>
              <span
                class="block mt-40 ml-32 origin-left scale-x-50 group-hover:scale-x-100 transition-transform ease-in duration-200 delay-200 border-gray-light border-t-[1px]"
              ></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script type="text/javascript">
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "MainHero",
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      plyr: null,
    };
  },
  mounted() {
    gsap.to(document.querySelector(".title"), {
      scrollTrigger: {
        trigger: ".title",
        start: "top center",
        end: "bottom center",
        scrub: 2,
        // markers: true,
      },
      x: "10%",
      duration: 3,
    });

    this.plyr = document.querySelector(".video-plyr");
    new Plyr(this.plyr, {
      autoplay: true,
      muted: true,
      loop: true,
      controls: false,
      iconPrefix: "plyr",
    });
  },
  methods: {
    mobilePlay: function() {
      document.body.addEventListener("touchstart", function() {
        this.plyr.play();
      });
    },
  },
};
</script>
