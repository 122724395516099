<template>
  <div
    class="relative md:absolute overflow-hidden top-0 left-0 z-10 h-40 md:h-full w-full md:w-40"
    style="background: linear-gradient(#f74f90, #b00443)"
  >
    <div
      class="animation-sidebar flex flex-col h-full justify-around items-center"
    >
      <p class="md:-rotate-90 w-max font-heading text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-main text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-heading text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-main text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-heading text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-main text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-heading text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-main text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-heading text-20 text-gray-dark">
        FVF Esport
      </p>
      <p class="md:-rotate-90 w-max font-main text-20 text-gray-dark">
        FVF Esport
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  components: {},
  data() {},
};
</script>
