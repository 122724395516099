<template>
  <div id="app" class="relative bg-gray-dark">
    <SideBar />
    <NavBar />
    <main class="grid gap-y-96 md:gap-y-192 overflow-x-hidden">
      <MainHero />
      <Roster />
      <Footer />
    </main>
  </div>
</template>

<script type="text/javascript">
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";
import MainHero from "@/components/MainHero.vue";
import Roster from "@/components/Roster.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavBar,
    SideBar,
    MainHero,
    Roster,
    Footer,
  },
  mounted: function() {},
};
</script>
